<template></template>
<script>
  const dateString = new Date().toISOString().slice(0, 10)

  export default {
    name: 'Sitemap',
    data() {
      return {
        data: [
          {
            path: '/',
            lastmod: dateString,
            priority: 1.0,
            changefreq: 'daily',
          },
          {
            path: '/franquicias',
            lastmod: dateString,
            priority: 0.8,
            changefreq: 'daily',
          },
          {
            path: '/franquicias-master',
            lastmod: dateString,
            priority: 0.8,
            changefreq: 'daily',
          },
          {
            path: '/asociaciones-franquicias',
            lastmod: dateString,
            priority: 0.8,
            changefreq: 'daily',
          },
          {
            path: '/consultores',
            lastmod: dateString,
            priority: 0.8,
            changefreq: 'daily',
          },
          {
            path: '/proveedores',
            lastmod: dateString,
            priority: 0.8,
            changefreq: 'daily',
          },
          {
            path: '/oferta-inmobiliaria',
            lastmod: dateString,
            priority: 0.8,
            changefreq: 'daily',
          },
          {
            path: '/sufranquicia',
            lastmod: dateString,
            priority: 0.7,
            changefreq: 'daily',
          },
          {
            path: '/blog',
            lastmod: dateString,
            priority: 0.7,
            changefreq: 'daily',
          },
          {
            path: '/noticias',
            lastmod: dateString,
            priority: 0.7,
            changefreq: 'daily',
          },
          {
            path: '/eventos',
            lastmod: dateString,
            priority: 0.7,
            changefreq: 'daily',
          },
        ],
      }
    },
    created() {
      this.getCompanies()
    },
    methods: {
      async getCompanies() {
        axios
          .get(process.env.VUE_APP_API_DIRECTORY + 'companies-list?active=1&limit=10000')
          .then((response) => {
            response.data.map((company) => {
              this.data.push({
                path: `/empresa/${company.link}`,
                lastmod: company.created_at
                  ? company.created_at.slice(0, 10)
                  : dateString,
                priority: 0.9,
                changefreq: 'daily',
              })
            })
            this.getPublications()
          })
      },
      async getPublications() {
        axios.get('publications?limit=3000').then((response) => {
          response.data.map((publication) => {
            this.data.push({
              path: `/publicaciones/${publication.slug}`,
              lastmod: publication.created_at
                ? publication.created_at.slice(0, 10)
                : dateString,
              priority: 0.9,
              changefreq: 'daily',
            })
          })
          console.log(JSON.stringify(this.data))
        })
      },
    },
  }
</script>
